import { enforceBasePath, loadMfsEntrypoints } from '@im/ui-lib/mf-utils';
import { mfNames } from './mfNames';

enforceBasePath('cep', 'app');

(async () => {
  await loadMfsEntrypoints('cep', ['mf_core', 'mf_shared'].concat(mfNames));

  await import(
    /* webpackChunkName: "app-mount" */
    './app-mount'
  );
})();

export {}; // Make file an ES module.
