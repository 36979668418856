/** List of all the MFs
 * `cep-remotes.json` file is not longer used.
 */
export const mfNames = [
  'account_mf',
  'bid_mf',
  'cart_mf',
  'checkout_mf',
  'cloudreports_mf',
  'cms_mf',
  'credit_mf',
  'dashboard_mf',
  'discount_mf',
  'invoice_mf',
  'loyaltymanagement_mf',
  'ost_mf',
  'payment_mf',
  'product_mf',
  'productconfigurator_mf',
  'configurationmanager_mf',
  'financecalculator_mf',
  'bidregistration_mf',
  'quotemgmt_mf',
  'return_mf',
  'sandbox_mf',
  'voucher_mf',
  'insight_mf',
  'subscriptions_mf',
  'vri_mf',
  'renewals_mf',
  'resellermanagement_mf',
  'empty_mf',
  'partneractivation_mf',
];
